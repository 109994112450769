import React, { useRef, useState } from 'react'
import axios from 'axios'
import JSZip from 'jszip'

import { FileUpload } from '@amzn/stencil-react-components/file-upload'
import { Input, InputHeader, InputWrapper } from '@amzn/stencil-react-components/form'
import { Col, Container, Spacer } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { Spinner } from '@amzn/stencil-react-components/spinner'
import { H1 } from '@amzn/stencil-react-components/text'

import { Button } from 'src/components/Button'
import { MUPPItemBankDTO } from 'src/models/dto/activities/MUPPItemBankDTO'
import { Authenticator } from 'src/services/Authenticator'
import {
    parseMUPPItemBankDTOFromCSVExport,
    parseMUPPItemBankDTOFromJSONExport,
} from 'src/services/mupp/MUPPItemBankExportParser'
import {
    ItemBankSubmissionRequest,
    MUPPItemBankService,
} from 'src/services/mupp/MUPPItemBankService'

export const ItemBankSubmissionPage = () => {
    const zipUploadRef = useRef<HTMLDivElement | null>(null)
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [request, setRequest] = useState<ItemBankSubmissionRequest | null>(null)
    const [requester, setRequester] = useState(() => Authenticator.getDefaultUserName())

    const onFileAttached = async (files: File[]) => {
        setMessage('')
        setError('')
        setIsLoading(true)
        const file = files[0]
        try {
            const zip = new JSZip()
            const zipFile = await zip.loadAsync(await file.arrayBuffer(), {
                checkCRC32: true,
            })
            let itemBankDTO: MUPPItemBankDTO | null
            itemBankDTO = await parseMUPPItemBankDTOFromCSVExport(zipFile)
            if (!itemBankDTO) {
                itemBankDTO = await parseMUPPItemBankDTOFromJSONExport(zipFile)
            }
            if (itemBankDTO) {
                setMessage(
                    `Item bank detected. Dimensions: ${itemBankDTO.availableDimensions.length}, ` +
                        ` items: ${itemBankDTO.catItems.length}`
                )
                setRequest({
                    author: requester,
                    itemBankDTO,
                })
            } else {
                setError('No item bank detected from the archive.')
            }
        } catch (e) {
            console.error(e)
            setError('Error while loading the archive.')
        } finally {
            setIsLoading(false)
        }
    }

    const submit = async () => {
        if (!request) {
            return
        }

        setIsLoading(true)
        setMessage('')
        setError('')
        try {
            const token = axios.CancelToken.source().token
            const versionId = await MUPPItemBankService.submitItemBank(request, token)
            setMessage(`Item bank submitted. Version ID: ${versionId}`)
        } catch (e) {
            console.error(e)
            setError('Error while submitting item bank.')
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Container paddingTop='S400' paddingBottom='S400' paddingHorizontal='S400'>
            <H1>Item Bank Submission</H1>
            {error && <MessageBanner type={MessageBannerType.Error}>{error}</MessageBanner>}
            {message && <MessageBanner type={MessageBannerType.Success}>{message}</MessageBanner>}
            <Col gridGap='S200'>
                <Spacer height='S200' />
                <InputHeader
                    htmlFor='item-bank-upload-zip-file'
                    labelText='Upload CSV or JSON export archive ("CSV Templates - <versionId>.zip" or "JSON Templates - <versionId>.zip")'
                />
                <FileUpload
                    ref={zipUploadRef}
                    id='item-bank-submission-file-input'
                    dataTestId='item-bank-submission-file-input'
                    accept='application/zip'
                    maxFiles={1}
                    onFileAttached={onFileAttached}
                />
                {isLoading && <Spinner />}
                {request && (
                    <Button dataTestId='item-bank-submission-submit' onClick={submit}>
                        Submit
                    </Button>
                )}
                <InputWrapper
                    labelText='Requester'
                    id='item-bank-submission-requester'
                    dataTestId='item-bank-submission-requester'
                    required
                    error={!requester}
                >
                    {(props) => (
                        <Input
                            {...props}
                            value={requester}
                            placeholder='(Auto detected)'
                            onChange={(e) => setRequester(e.target.value)}
                        />
                    )}
                </InputWrapper>
            </Col>
        </Container>
    )
}
